#book {
    position: absolute;
    top: 0px;
    left: 0px;
    margin: 30px;
    padding-left: 40px;
    padding-right: 40px;
    height: 100%;
    background-color: #1e1e1e;
    z-index: 12;
    font-family: cursive;
    font-size: larger;
}

#previousPageButton {
    position: absolute;
    top: 25px;
    left: 0px;
    background-color: white;
    width: 30px;
    height: 30px;
    clip-path: polygon(100% 0, 100% 100%, 40% 50%);
}

#previousPageButton:hover {
    background-color: gray;
}

#nextPageButton {
    position: absolute;
    top: 25px;
    right: 0px;
    background-color: white;
    width: 30px;
    height: 30px;
    clip-path: polygon(0 0, 0 100%, 60% 50%);
}

#nextPageButton:hover {
    background-color: gray;
}


.bookPageMaze {
    width: 450px;
    height: 450px;
    background-image: url(../img/puzzles/mazeMap.png);
}