#book {
  height: 100%;
  z-index: 12;
  background-color: #1e1e1e;
  margin: 30px;
  padding-left: 40px;
  padding-right: 40px;
  font-family: cursive;
  font-size: larger;
  position: absolute;
  top: 0;
  left: 0;
}

#previousPageButton {
  width: 30px;
  height: 30px;
  clip-path: polygon(100% 0, 100% 100%, 40% 50%);
  background-color: #fff;
  position: absolute;
  top: 25px;
  left: 0;
}

#previousPageButton:hover {
  background-color: gray;
}

#nextPageButton {
  width: 30px;
  height: 30px;
  clip-path: polygon(0 0, 0 100%, 60% 50%);
  background-color: #fff;
  position: absolute;
  top: 25px;
  right: 0;
}

#nextPageButton:hover {
  background-color: gray;
}

.bookPageMaze {
  width: 450px;
  height: 450px;
  background-image: url("mazeMap.975752a8.png");
}

/*# sourceMappingURL=index.4b456da9.css.map */
